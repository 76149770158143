@import "variables.css";

.cta__section {
  background-color: var(--background-color-light);
  width: 100vw;
  height: 50rem;
  padding-top: 5.2rem;
}

.button__container {
  padding: 2.4rem;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
}

.button__contact {
  padding: 1.2rem 4.8rem;
  background-color: #ffe066;
  border-radius: 1.4rem;
  border-inline: solid 0.3rem #ffe066;
  color: var(--dark-color);
  text-decoration: none;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
}

.button__contact:hover {
  background-color: var(--background-color-light);
  color: var(--dark-color-tint);
}

.button__cv {
  border: solid 0.2rem #ffe066;
  padding: 1.2rem 2.4rem;
  border-radius: 1.4rem;
  color: var(--dark-color);

  text-decoration: none;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  transition: all 0.3s;
}

.button__cv:hover {
  border: solid 0.2rem rgba(0, 0, 0, 0);
  color: var(--dark-color-tint);
}

.button__icon {
  font-size: 2.8rem;
  text-align: center;
}

.social__link.cta__socials {
  font-size: 4.8rem;
  color: var(--secondary-color);
}
