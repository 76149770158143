.cta__section {
  background-color: var(--background-color-light);
  width: 100vw;
  height: 50rem;
  padding-top: 5.2rem;
}

.button__container {
  justify-content: center;
  align-items: center;
  max-width: 50rem;
  padding: 2.4rem;
  font-size: 2rem;
  display: flex;
}

.button__contact {
  color: var(--dark-color);
  background-color: #ffe066;
  border-inline: .3rem solid #ffe066;
  border-radius: 1.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 4.8rem;
  font-weight: bold;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.button__contact:hover {
  background-color: var(--background-color-light);
  color: var(--dark-color-tint);
}

.button__cv {
  color: var(--dark-color);
  border: .2rem solid #ffe066;
  border-radius: 1.4rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem 2.4rem;
  font-weight: bold;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.button__cv:hover {
  color: var(--dark-color-tint);
  border: .2rem solid #0000;
}

.button__icon {
  text-align: center;
  font-size: 2.8rem;
}

.social__link.cta__socials {
  color: var(--secondary-color);
  font-size: 4.8rem;
}

/*# sourceMappingURL=index.d5c6a2a6.css.map */
